import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Donations from './pages/Donations';
import Events from './pages/Events';
import Galleries from './pages/Galleries';
import EventDetails from './pages/EventDetails';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Sermons from './pages/Sermons';
import Contact from './pages/Contact';
import Layout from './components/Layout';

import './App.css';

export default function App() {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Layout />,
            children: [
                {
                    path: '/',
                    element: <Home />
                },
                {
                    path: 'about',
                    element: <About />
                },
                {
                    path: 'donations',
                    element: <Donations />
                },
                {
                    path: 'privacy',
                    element: <PrivacyPolicy />
                },
                {
                    path: 'galleries',
                    element: <Galleries />
                },
                {
                    path: 'contacts',
                    element: <Contact />
                },
                {
                    path: 'events',
                    element: <Events />
                },
                {
                    path: 'events/:slug',
                    element: <EventDetails />
                },
                {
                    path: 'sermons',
                    element: <Sermons />
                }
            ]
        }
    ]);

    return <RouterProvider router={router} />;
}
