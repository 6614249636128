import { IPodcast } from '../types/podcast';
import { ENV } from '../utils/contants';

export default function AudioMessage({
    title,
    genre,
    audios,
    author
}: IPodcast) {
    return (
        <article className='mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl my-4'>
            <div className='bg-white p-4 border-l-4 border-t-4 border-indigo-600'>
                <h1 className='my-4 text-2xl'>{title}</h1>
                <span className='text-gray-500'>{genre}</span>
                {audios && audios.length > 0 && (
                    <audio controls className='my-8'>
                        <source
                            src={`${ENV.audios}/${audios[0].url}`}
                            type='audio/mpeg'
                        />
                        Your browser does not support the audio element.
                    </audio>
                )}
                {author && (
                    <p className='text-gray-500'>Delivered by: {author}</p>
                )}
            </div>
        </article>
    );
}
