import NoData from '../components/NoData';
import PrimaryHeaders from '../components/PrimaryHeaders';
import { getGalleryImageUrls } from '../utils/requests';
import { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';

export default function GalleriesPage() {
    const [imagesUrls, setImageUrls] = useState<string[]>([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handleNextImage = () => {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % imagesUrls.length);
    };

    const handlePrevImage = () => {
        setCurrentImageIndex(
            prevIndex => (prevIndex - 1 + imagesUrls.length) % imagesUrls.length
        );
    };

    useEffect(() => {
        (async () => {
            const responseData = await getGalleryImageUrls();
            setImageUrls(responseData);
        })();
    }, []);

    return (
        <div className='my-20 py-8'>
            {imagesUrls && imagesUrls.length > 0 ? (
                <div className='md:max-w-7xl m-auto px-4 sm:px-8 bg-slate-50 rounded-lg shadow-sm p-8'>
                    <PrimaryHeaders
                        className='text-center'
                        title='Gallery'
                        subTitle='Some of our great moments'
                    />
                    {imagesUrls && imagesUrls.length > 0 && (
                        <img
                            src={imagesUrls[currentImageIndex]}
                            alt='Gallery'
                            className='rounded md:w-full md:h-full mt-16'
                            width={900}
                            height={100}
                        />
                    )}
                    <div className='w-[10rem] bg-gray-200 h-10 flex justify-between items-center m-auto mt-4 p-4 rounded-3xl'>
                        <div
                            className='w-6 h-6 cursor-pointer'
                            onClick={handlePrevImage}
                        >
                            <FaChevronLeft
                                className='h-full w-full text-indigo-600'
                                aria-hidden='true'
                            />
                        </div>
                        <div
                            className='w-6 h-6 right-0 cursor-pointer'
                            onClick={handleNextImage}
                        >
                            <FaChevronRight
                                className='h-full w-full text-indigo-600'
                                aria-hidden='true'
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <NoData />
            )}
        </div>
    );
}
