import { ITestimonial } from '../types/testimonial';
import { ENV } from '../utils/contants';

export default function TestimonialCard({ ...props }: ITestimonial) {
    return (
        <article className='flex flex-col border p-4 rounded-lg text-gray-500 md:w-[25rem] w-full'>
            <p className='my-2 italic'>{`"${props.content}"`}</p>
            <div className='flex items-center'>
                {props.images && props.images.length > 0 && (
                    <div className='shrink-0 w-16 h-16'>
                        <img
                            src={`${ENV.images}/${props.imageUrl}`}
                            alt='Author'
                            className='rounded-full object-cover w-full h-full'
                            width={100}
                            height={100}
                        />
                    </div>
                )}
                <div className='flex flex-col'>
                    <p className='ml-4 font-bold'>{`${props.firstName} ${props.lastName}`}</p>
                    <p className='ml-4'>{props.membership}</p>
                </div>
            </div>
        </article>
    );
}
