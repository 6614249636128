export const ENV = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    audios: process.env.REACT_APP_AUDIO_URL,
    images: process.env.REACT_APP_IMAGE_URL,
    attachments: process.env.REACT_APP_ATTACHMENT_URL
};

export const PODCAST_GENRES = {
    sermon: 'sermon',
    praise: 'praise',
    praiseAndWorship: 'praise & worship',
    worship: 'worship'
};
