import { IPaginate } from '../types/pagination';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

type PaginationProps = {
    onHandlePageChange: (
        direction: 'prev' | 'next' | 'specific',
        pageNumber?: number
    ) => void;
} & Pick<IPaginate, 'total' | 'perPage' | 'currentPage'>;

export default function Pagination({
    total,
    perPage,
    currentPage,
    onHandlePageChange
}: PaginationProps) {
    const handlePageChange = (
        direction: 'prev' | 'next' | 'specific',
        pageNumber?: number
    ) => {
        let newPage = currentPage;

        if (direction === 'prev') {
            newPage = Math.max(currentPage - 1, 1);
        } else if (direction === 'next') {
            newPage = Math.min(currentPage + 1, getTotalTabs());
        } else if (direction === 'specific' && pageNumber) {
            newPage = pageNumber;
        }

        onHandlePageChange(direction, newPage);
    };

    const getTotalTabs = () => {
        if (total <= 0 || perPage <= 0) {
            throw new Error(
                'Both total item and per page must be positive numbers!'
            );
        }
        return Math.ceil(total / perPage);
    };

    const totalTabs = getTotalTabs();

    const renderPaginationTabs = (): JSX.Element[] => {
        const tabs: JSX.Element[] = [];
        for (let i = 1; i <= totalTabs; i++) {
            tabs.push(
                <Link
                    onClick={() => handlePageChange('specific', i)}
                    key={i}
                    to='#'
                    className={`${
                        i === currentPage ? 'bg-blue-800' : ''
                    } relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                        i === currentPage ? 'text-white' : 'text-gray-900'
                    } ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0 md:inline-flex`}
                >
                    {i}
                </Link>
            );
        }
        return tabs;
    };

    return (
        <div
            className={`max-w-lg  m-auto flex items-center justify-center border-t border-gray-200 bg-white px-4 py-3 sm:px-6`}
        >
            {/* Previous and Next Links for Mobile View */}
            <div className='flex justify-between sm:hidden gap-4'>
                <Link
                    to='#'
                    onClick={() => handlePageChange('next')}
                    className='relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'
                >
                    Previous
                </Link>
                <Link
                    to='#'
                    onClick={() => handlePageChange('prev')}
                    className='relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'
                >
                    Next
                </Link>
            </div>

            {/* Pagination Tabs for Desktop View */}
            <div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
                <div>
                    <nav
                        className='isolate inline-flex -space-x-px rounded-md shadow-sm'
                        aria-label='Pagination'
                    >
                        {/* Previous Link */}
                        <Link
                            to='#'
                            onClick={() => handlePageChange('prev')}
                            className='relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                        >
                            <span className='sr-only'>Previous</span>
                            <FaChevronLeft
                                className='h-5 w-5'
                                aria-hidden='true'
                            />
                        </Link>

                        {/* Pagination Tabs */}
                        {renderPaginationTabs()}

                        {/* Next Link */}
                        <Link
                            to='#'
                            onClick={() => handlePageChange('next')}
                            className='relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                        >
                            <span className='sr-only'>Next</span>
                            <FaChevronRight
                                className='h-5 w-5'
                                aria-hidden='true'
                            />
                        </Link>
                    </nav>
                </div>
            </div>
        </div>
    );
}
