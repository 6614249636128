import {
    FaFacebook,
    FaXTwitter,
    FaInstagram,
    FaEnvelope,
    FaPhone,
    FaHeart
} from 'react-icons/fa6';

import { Link } from 'react-router-dom';

export interface IProgramme {
    day: string;
    title: string;
    startsAt: string;
    endsAt: string;
    venue: string;
    anchor?: string;
    isTakingPlace: boolean;
}

const programmes: IProgramme[] = [
    {
        day: 'Monday',
        title: 'Marriage Conselling',
        startsAt: '17:00',
        endsAt: '18:00',
        venue: 'church',
        anchor: 'church',
        isTakingPlace: false
    },
    {
        day: 'Tuesday',
        title: 'House Fellowship',
        startsAt: '17:00',
        endsAt: '18:00',
        venue: 'Online',
        anchor: 'Pastor B. Bervall',
        isTakingPlace: false
    },
    {
        day: 'Wednesday',
        title: 'Bible Study',
        startsAt: '17:00',
        endsAt: '18:00',
        venue: 'Online',
        anchor: 'Pastor B. Bergvall',
        isTakingPlace: true
    },
    {
        day: 'Thursday',
        title: 'The Power of Marriage as Described By The Bible',
        startsAt: '2023-10-15',
        endsAt: '2023-10-15',
        venue: 'church',
        anchor: undefined,
        isTakingPlace: false
    },
    {
        day: 'Friday',
        title: 'Prayer Meeting',
        startsAt: '17:00',
        endsAt: '18:00',
        venue: 'Online',
        anchor: undefined,
        isTakingPlace: true
    },
    {
        day: 'Saturday',
        title: 'School of Disciples',
        startsAt: '17:00',
        endsAt: '18:00',
        venue: 'church',
        anchor: 'Pastor B. Bergvall',
        isTakingPlace: false
    },
    {
        day: 'Sunday',
        title: 'Church Service',
        startsAt: '14:00',
        endsAt: '17:00',
        venue: 'Main Church',
        anchor: 'Pastor B. Bergvall',
        isTakingPlace: true
    }
];

const footerLinks = [
    { name: 'Contacts', link: '/contacts' },
    { name: 'Donations', link: '/donations' },
    { name: 'Events', link: '/events' },
    { name: 'Gallery', link: '/galleries' },
    { name: 'Privacy Policy', link: '/privacy' }
];

export default function Footer() {
    return (
        <footer className='bg-gray-700 px-4'>
            <div className='flex flex-col border-b md:max-w-7xl m-auto'>
                <div className='flex justify-center text-white py-16 flex-col md:flex-row md:justify-between'>
                    <div className='my-4 tracking-wider'>
                        <h1 className='mb-6 uppercase'>Address</h1>
                        <p className='text-slate-400 text-sm'>
                            Vähä-hamenkatu 12,
                        </p>
                        <p className='text-slate-400 text-sm'>20398</p>
                        <p className='text-slate-400 text-sm'>Turku, Finland</p>
                        <div className='flex items-center mt-4'>
                            <FaEnvelope className='h-4 w-4 fill-slate-400' />
                            <span className='text-slate-400 ml-2'>
                                <p className='text-sm'>
                                    <a
                                        href='mailto:info@gracechapel.fi'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        info@gracechapel.fi
                                    </a>
                                </p>
                            </span>
                        </div>
                        <div className='flex items-center mt-4'>
                            <FaPhone className='h-4 w-4 fill-slate-400' />
                            <span className='text-slate-400 ml-2'>
                                <p className='text-sm'>+358-45-6352887</p>
                            </span>
                        </div>
                    </div>
                    <div className='my-4 tracking-wider'>
                        <h1 className='mb-6 uppercase'>Weekly Programmes</h1>
                        <ul>
                            {programmes.map(
                                programme =>
                                    programme.isTakingPlace && (
                                        <li
                                            key={programme.day}
                                            className='mb-4'
                                        >
                                            <p className='my-2'>
                                                {programme.day}
                                            </p>
                                            <p className='text-slate-400 text-sm'>
                                                {programme.title} {' | '}
                                                <span>
                                                    {programme.startsAt} -{' '}
                                                    {programme.endsAt}
                                                </span>
                                                {' | '} {programme.venue}
                                            </p>
                                        </li>
                                    )
                            )}
                        </ul>
                    </div>
                    <div className='my-4 tracking-wider'>
                        <h1 className='mb-6 uppercase'>Links</h1>
                        <ul>
                            {footerLinks.map(item => (
                                <li
                                    className='text-slate-400 mb-3 hover:text-slate-300'
                                    key={item.name}
                                >
                                    <Link to={item.link} key={item.name}>
                                        <p className='text-sm'>{item.name}</p>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <ul className='flex justify-center mb-6'>
                    <li className='cursor-pointer'>
                        <a
                            href='https://www.facebook.com/search/top?q=rccg%20grace%20chapel%20turku'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <FaFacebook className='fill-blue-400 h-7 w-7' />
                        </a>
                    </li>
                    <li className='mx-6 cursor-pointer'>
                        <FaXTwitter className='fill-slate-200 h-7 w-7' />
                    </li>
                    <li className='cursor-pointer '>
                        <FaInstagram className='h-7 w-7 fill-pink-200' />
                    </li>
                </ul>
            </div>
            <div className='flex flex-col justify-center items-center text-center py-10 text-slate-400 gap-3 m-auto'>
                <div className='flex gap-1 items-center'>
                    <p>Built with</p>
                    <p>
                        <FaHeart className='h-4 w-4 fill-orange-600' />
                    </p>
                    <p>by</p>
                    <a
                        href='https://dayooladapo.com'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='hover:text-slate-200'
                    >
                        Dayo Oladapo
                    </a>{' '}
                </div>
                <small>&copy; {new Date().getFullYear()} gracechapel.fi</small>
            </div>
        </footer>
    );
}
