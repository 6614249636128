import { useEffect, useState } from 'react';
import PrimaryHeaders from '../components/PrimaryHeaders';
import { IPodcast } from '../types/podcast';
import AudioMessage from '../components/AudioMessage';
import Pagination from '../components/Pagination';
import { transformToCamelCase } from '@church/utilities';
import { IPaginate } from '../types/pagination';
import { getPaginatedSermons } from '../utils/requests';
import NoData from '../components/NoData';

export default function Sermons() {
    const [paginatedData, setPaginatedData] = useState<IPaginate>();
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        (async () => {
            const responseData = await getPaginatedSermons(currentPage, 20);
            setPaginatedData(transformToCamelCase(responseData));
        })();
    }, [currentPage]);

    const handlePageChange = (
        direction: 'prev' | 'next' | 'specific',
        pageNumber?: number
    ) => {
        if (direction === 'specific' && pageNumber) {
            setCurrentPage(pageNumber);
        } else if (direction === 'prev') {
            setCurrentPage(currentPage - 1);
        } else if (direction === 'next') {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <section className='my-20 py-8'>
            {paginatedData && paginatedData.data.length > 0 ? (
                <>
                    <div className='md:max-w-7xl m-auto px-4 sm:px-8 bg-slate-50 rounded-lg shadow-sm'>
                        <div className='text-center pt-8'>
                            <PrimaryHeaders
                                title='Sermons'
                                subTitle='All messages from the altar'
                            />
                        </div>
                        {paginatedData &&
                            paginatedData.data &&
                            paginatedData.data.length > 0 && (
                                <div className='flex justify-center items-center gap-2 py-8 flex-wrap'>
                                    {paginatedData.data.map(
                                        (message: IPodcast) => (
                                            <AudioMessage
                                                id={message.id}
                                                key={message.id}
                                                title={message.title}
                                                audios={message.audios}
                                                summary={message.summary}
                                                status={message.status}
                                                genre={message.genre}
                                                author={message.author}
                                            />
                                        )
                                    )}
                                </div>
                            )}
                    </div>
                    {paginatedData &&
                        paginatedData.total > paginatedData.perPage && (
                            <Pagination
                                onHandlePageChange={handlePageChange}
                                total={paginatedData.total}
                                perPage={paginatedData.perPage}
                                currentPage={paginatedData.currentPage}
                            />
                        )}
                </>
            ) : (
                <NoData />
            )}
        </section>
    );
}
