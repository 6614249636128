// import React from 'react';
import { FaArrowRight } from 'react-icons/fa6';
// import button from '../components/button';
import AudioMessage from '../components/AudioMessage';
// import { blogs } from '@/data/blog';
// import Blog from '@/components/Blog';
import Event from '../components/Event';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PrimaryHeaders from '../components/PrimaryHeaders';
import DefaultBgImage from '../components/DefaultBgImage';
import GalleryImage from '../components/GalleryImage';
import { IEvent } from '../types/event';
import { IPodcast } from '../types/podcast';
import {
    getAnnouncement,
    getLimitedPublishedEventsPodcastsAndGalleries
} from '../utils/requests';
import Banner from '../components/Banner';
import { IAnnouncement } from '../types/announcement';

import coverImage_1 from '../assets/church-gallery-8.jpg';
import coverImage_2 from '../assets/church-gallery-7.jpg';
import bibleImage from '../assets/bible.jpg';
import coverImage_3 from '../assets/bible-the-way.jpg';

type LandingData = {
    music: IPodcast[];
    sermons: IPodcast[];
    events: IEvent[];
    galleries: string[];
};

export default function Home() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [data, setData] = useState<LandingData>();
    const [announcement, setAnnouncement] = useState<IAnnouncement[]>([]);
    const [show, setShow] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            const responseData =
                await getLimitedPublishedEventsPodcastsAndGalleries();
            setData(responseData);
        })();
    }, []);

    const slides = [
        { image: coverImage_1, text: 'place of freedom' },
        { image: coverImage_2, text: 'filling station' },
        { image: coverImage_3, text: 'meeting place' }
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide(prevSlide => (prevSlide + 1) % slides.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [slides.length]);

    useEffect(() => {
        const userInformed = sessionStorage.getItem('informed');
        if (userInformed) {
            setShow(false);
        } else {
            const fetchData = async () => {
                const responseData = await getAnnouncement();
                setAnnouncement(responseData);
            };

            fetchData();
        }
    }, []);

    const handleAnnouncementInStorage = () => {
        sessionStorage.setItem('informed', JSON.stringify({ informed: true }));
        setShow(false);
    };

    return (
        <main>
            {announcement && announcement.length === 1 && show && (
                <Banner
                    onClose={handleAnnouncementInStorage}
                    title={announcement[0].title}
                    description={announcement[0].description}
                />
            )}
            {/* Hero */}
            <section
                className='relative overflow-hidden h-screen'
                style={{ backgroundImage: `url(${bibleImage})` }}
            >
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className={`absolute top-0 left-0 w-full h-full bg-cover bg-center transition-opacity duration-1000 ${
                            currentSlide === index ? 'opacity-1' : 'opacity-0'
                        }`}
                        style={{
                            backgroundImage: `url(${slide.image})`
                        }}
                    >
                        <div className='container mx-auto h-full flex items-center justify-center relative z-10 text-center'>
                            <h2 className='text-7xl font-bold leading-tight mb-4 uppercase lg:text-9xl md:text-9xl text-white'>
                                {slide.text}
                            </h2>
                        </div>
                    </div>
                ))}
            </section>
            {/* What we stand for */}
            <section className='md:flex justify-between items-center flex-wrap md:max-w-7xl m-auto py-8 px-4 mb-20 md:items-center relative isolate overflow-hidden bg-white sm:py-32 lg:overflow-visible lg:px-0'>
                <DefaultBgImage />
                <div className='md:w-[20rem] rounded-lg'>
                    <img
                        className='rounded-lg object-cover w-full'
                        src={coverImage_3}
                        alt='Bible'
                    />
                </div>
                <div className='max-w-2xl flex flex-col items-start leading-8 p-4 m-auto flex-wrap'>
                    <h1 className='text-base font-semibold leading-7 text-indigo-600 uppercase'>
                        Our values
                    </h1>
                    <h2 className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center my-4'>
                        <span className='uppercase tracking-wider'>God</span> is{' '}
                        <span className='uppercase tracking-wider'>love</span>
                    </h2>
                    <p className='mt-6 text-xl leading-8 text-gray-700'>
                        Welcome to our online space dedicated to the
                        foundational truth that shapes our faith journey:
                        &ldquo;God is love.&ldquo; I&apos;m{' '}
                        <strong>Björn Bergvall</strong>, your pastor, and I am
                        excited to share with you the profound significance of
                        this simple yet powerful declaration that underpins the
                        core of our Christian beliefs. In the tapestry of our
                        faith, we find the threads of love intricately woven
                        throughout the pages of the Bible. From Genesis to
                        Revelation, the scriptures reveal God&rsquo;s unwavering
                        love as the heartbeat of our spiritual narrative. As it
                        says in 1 John 4:8, &ldquo;Whoever does not love does
                        not know God, because God is love.&ldquo; This truth is
                        not just a theological concept; it is the essence of who
                        our Creator is—a boundless, unconditional love that
                        surpasses human understanding.
                    </p>

                    <Link
                        to='/about'
                        className='inline-block py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white cursor-pointer tracking-wider mt-5 bg-gray-600 hover:bg-gray-700'
                    >
                        MORE ABOUT US
                    </Link>
                </div>
            </section>
            {/* Events */}
            {data && data.events && data.events.length > 0 && (
                <section className='flex justify-center flex-col flex-wrap m-auto  py-8 px-4 my-20 bg-gradient-to-r from-transparent from-5% via-slate-100 via-20%  to-transparent'>
                    <div className='md:flex justify-center max-w-7xl mx-auto mb-8'>
                        <div className='h-fit w-fit'>
                            <PrimaryHeaders
                                title='Our events'
                                subTitle='Calendar'
                            />
                        </div>
                        <div className='m-auto overflow-hidden md:w-2/4 py-4'>
                            <p className='leading-wider'>
                                Due to the fact that some of the events are
                                subject to change, prospective participants are
                                therefore advised to contact the church for any
                                possible registration procedures and changes.
                            </p>
                        </div>
                        <div>
                            <Link
                                to='/events'
                                className='inline-block p-2 text-sm font-medium cursor-pointer tracking-wider bg-gray-600 hover:bg-gray-700 text-white rounded-md'
                            >
                                <div className='flex items-center'>
                                    <p>more</p>
                                    <FaArrowRight className='w-4 h-4 fill-white-500 ml-2' />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <ul className='mx-auto'>
                        {data.events.map(
                            (
                                event: IEvent,
                                index: number,
                                eventsArr: IEvent[]
                            ) => (
                                <li
                                    key={event.id}
                                    className={` ${
                                        index < data.events.length - 1 &&
                                        eventsArr.length >= 2
                                            ? 'border-b-2'
                                            : ''
                                    }`}
                                >
                                    <Event
                                        id={event.id}
                                        slug={event.slug}
                                        title={event.title}
                                        startDate={event.startDate}
                                        endDate={event.endDate}
                                        fee={event.fee}
                                        isPublished={event.isPublished}
                                        organizer={event.organizer}
                                        email={event.email}
                                        telephone={event.telephone}
                                        images={event.images}
                                        location={event.location}
                                        description={event.description}
                                    />
                                </li>
                            )
                        )}
                    </ul>
                </section>
            )}
            {/* Sample Worship Sound */}
            {data &&
                data.music &&
                data.music.length > 0 &&
                data.music[0].audios.length > 0 && (
                    <section className='mb-20 -mt-20 py-8 bg-gradient-to-r from-transparent from-5% via-gray-200 via-30%  to-transparent'>
                        <div className='m-auto md:max-w-7xl px-4'>
                            <PrimaryHeaders
                                title='music'
                                subTitle='The sound of our worship'
                                className='text-center'
                            />
                            {data.music.map((music: IPodcast) => (
                                <div key={music.id} className='mt-16'>
                                    <AudioMessage
                                        id={music.id}
                                        audios={music.audios}
                                        status={music.status}
                                        summary={music.summary}
                                        title={music.title}
                                        genre={music.genre}
                                        author={music.author}
                                    />
                                </div>
                            ))}
                        </div>
                    </section>
                )}

            {/* The Gallery */}
            {data && data.galleries && data.galleries.length > 0 && (
                <section className='bg-gradient-to-r from-transparent from-5% via-blue-200 via-30%  to-transparent -mt-20 pt-20'>
                    <div className='md:max-w-7xl lg:max-w-7xl xl:max-w-7xl m-auto p-4'>
                        <div className='flex justify-center flex-wrap gap-4 my-20'>
                            {data.galleries.map(imageUrl => (
                                <GalleryImage src={imageUrl} key={imageUrl} />
                            ))}
                        </div>
                    </div>
                </section>
            )}
            {/* Audio sermons */}
            {data && data.sermons && data.sermons.length > 0 && (
                <section className='my-20 py-8'>
                    <div className='m-auto max-w-7xl px-4 mb-8'>
                        <PrimaryHeaders
                            title='podcast'
                            subTitle='Latest messages'
                            className='text-center'
                        />
                        <div className='mt-16'>
                            {data.sermons.map((sermon: IPodcast) => (
                                <AudioMessage
                                    id={sermon.id}
                                    key={sermon.id}
                                    title={sermon.title}
                                    audios={sermon.audios}
                                    summary={sermon.summary}
                                    status={sermon.status}
                                    genre={sermon.genre}
                                    author={sermon.author}
                                />
                            ))}
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <Link
                            to='/sermons'
                            className='inline-block py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white cursor-pointer tracking-wider my-3 bg-gray-600 hover:bg-gray-700'
                        >
                            MORE MESSAGES
                        </Link>
                    </div>
                </section>
            )}
            {/* Latest and Popular Blog Posts --- to be introduced in the next version!*/}
            {/* {blogs && blogs.length > 0 && (
                <section className='my-20 py-8  bg-gradient-to-r from-transparent to-slate-100 -mb-8'>
                    <div className='md:max-w-7xl m-auto px-4'>
                        <div className='text-center'>
                            <PrimaryHeaders
                                title='blogs'
                                subTitle='From our blog posts'
                            />
                        </div>
                        <ul className='flex justify-center items-center md:gap-4 py-8 flex-wrap gap-y-4'>
                            {blogs.map(blog => (
                                <li key={blog.id}>
                                    <Blog
                                        id={blog.id}
                                        author={blog.author}
                                        title={blog.title}
                                        postedOn={blog.postedOn}
                                        imageUrl={blog.imageUrl}
                                        comments={blog.comments}
                                    />
                                </li>
                            ))}
                        </ul>
                        <Link href='/blogs'>
                            <button
                                type='button'
                                className='flex mt-4 mb-14 bg-gray-600 hover:bg-gray-700 m-auto'
                            >
                                READ ALL ARTICLES
                            </button>
                        </Link>
                    </div>
                </section>
            )} */}
        </main>
    );
}
