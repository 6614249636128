import { IGallery } from '../types/gallery';
import { ENV, PODCAST_GENRES } from './contants';
import { IPodcast } from '../types/podcast';
import { IEvent } from '../types/event';

type LandingData = {
    music: IPodcast[];
    sermons: IPodcast[];
    events: IEvent[];
    galleries: string[];
};

export class Http {
    static async get(url: string) {
        try {
            const response = await fetch(url);
            if (response.ok) {
                const responseData = await response.json();
                return responseData.data;
            }
        } catch (error) {
            /* empty */
        } // Errors are intended to bubble up
    }
}

export const getData = async (uri: string) => {
    return await Http.get(uri);
};

export const getGalleryImageUrls = async () => {
    const imageUrls: string[] = [];
    const responseData: IGallery[] = await getData(
        `${ENV.baseUrl}/galleries/images/all`
    );
    if (responseData.length > 0) {
        const galleryResults = responseData;
        galleryResults.forEach((result: IGallery) => {
            if (result.images && result.images.length > 0) {
                result.images.forEach(image => {
                    imageUrls.push(`${ENV.images}/${image.url}`);
                });
            }
        });
    }
    return imageUrls;
};

export const getLimitedEvents = async () => {
    return await getData(`${ENV.baseUrl}/events/status/upcoming/limited`);
};

export const getEventById = async (id?: string | string[]) => {
    return await getData(`${ENV.baseUrl}/events/status/upcoming/${id}`);
};

export const getTestimonials = async () => {
    return await getData(
        `${ENV.baseUrl}/testimonials/status/published/limited`
    );
};

export const getPaginatedSermons = async (
    currentPage: number,
    perPage: number
) => {
    return await getData(
        `${
            ENV.baseUrl
        }/podcasts/published/all/paginate?page=${currentPage}&&perPage=${perPage}&&genre[]=${[
            PODCAST_GENRES.sermon
        ]}`
    );
};

export const getLimitedPublishedEventsPodcastsAndGalleries = async () => {
    const musicGenres = [
        PODCAST_GENRES.praise,
        PODCAST_GENRES.praiseAndWorship,
        PODCAST_GENRES.worship
    ];
    const genreQueryString = musicGenres
        .map(genre => `genre[]=${encodeURIComponent(genre)}`)
        .join('&');
    const urls = [
        `podcasts/published/all?${genreQueryString}`,
        `podcasts/published/all?genre[]=${[PODCAST_GENRES.sermon]}&&limit=${3}`,
        'events/status/upcoming/limited',
        'galleries/images/limited'
    ];
    const initialData: LandingData = {
        music: [],
        sermons: [],
        events: [],
        galleries: []
    };

    const promises = urls.map(async url => {
        const responseData = await getData(`${ENV.baseUrl}/${url}`);
        if (responseData && responseData.length > 0) {
            if (musicGenres.includes(responseData[0].genre)) {
                initialData.music = responseData;
            } else if (responseData[0].genre === PODCAST_GENRES.sermon) {
                initialData.sermons = responseData;
            } else if (responseData[0].organizer) {
                initialData.events = responseData;
            } else {
                for (const element of responseData) {
                    if (element.images && element.images.length > 0) {
                        initialData.galleries.push(
                            `${ENV.images}/${element.images[0].url}`
                        );
                    }
                }
            }
        }
    });

    await Promise.all(promises);
    return initialData;
};

export const getAnnouncement = async () => {
    return await getData(`${ENV.baseUrl}/announcements/published/one`);
};
