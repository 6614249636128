import { FaCalendarDays } from 'react-icons/fa6';
import { CustomDate } from '@church/utilities';
import { IEvent } from '../types/event';
import { ENV } from '../utils/contants';
import { Link } from 'react-router-dom';

export default function EventListItem({
    slug,
    startDate,
    endDate,
    title,
    location,
    images,
    fee
}: IEvent) {
    return (
        <Link
            className={`relative flex justify-center py-8 gap-2 leading-8 border-t-2 cursor-pointer border-gray-100 max-w-4xl flex-col md:flex-row md:justify-between`}
            to={`/events/${slug}`}
        >
            <p className='absolute -top-4 pr-4 bg-white font-bold'>
                {CustomDate.getMonth(startDate, 'long')}
            </p>
            <div>
                <p className='uppercase text-2xl text-gray-500 font-bold'>
                    {CustomDate.getDay(startDate)}
                </p>
            </div>
            <div className='flex flex-col'>
                <div className='flex items-center'>
                    <FaCalendarDays className='h-4 w-4 hidden fill-slate-400 mr-1 md:block' />
                    <p className='text-gray-500 md:text-lg text-sm'>
                        {CustomDate.formatCustomDate(startDate)} {'-'}
                    </p>
                    <p className='text-gray-500 ml-1 md:text-lg text-sm'>
                        {CustomDate.formatCustomDate(endDate)}
                    </p>
                </div>
                <h1 className='uppercase font-bold'>{title}</h1>
                <p className='uppercase font-medium md:text-lg text-sm'>
                    {location}
                </p>
                <p className='md:text-2xl text-gray-500'>{`${
                    +fee === 0 ? 'Free' : fee + '€'
                }`}</p>
            </div>
            {images && images.length > 0 && (
                <div className='md:w-96 h-52'>
                    <img
                        src={`${ENV.images}/${images[0].url}`}
                        alt='Bible'
                        className='rounded-lg w-full h-full object-cover'
                    />
                </div>
            )}
        </Link>
    );
}
