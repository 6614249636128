import { Link } from 'react-router-dom';

export default function GalleryImage({ src }: { src: string }) {
    return (
        <Link to='/galleries'>
            <div className='rounded-md md:w-[20rem]'>
                <img
                    className='w-full h-full object-cover rounded-md'
                    src={src}
                    alt='Gallery'
                />
            </div>
        </Link>
    );
}
