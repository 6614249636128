import { FaCircleStop } from 'react-icons/fa6';

type NoDataProps = {
    className?: string;
};

export default function NoData({ className }: NoDataProps) {
    return (
        <div className={`${className} h-80`}>
            <FaCircleStop
                className='mt-8 h-32 w-32 text-red-600 m-auto'
                aria-hidden='true'
            />
        </div>
    );
}
