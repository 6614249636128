export const transformToCamelCase = (obj) => {
    if (Array.isArray(obj)) {
        if (obj.length === 0) {
            return [];
        }
        return obj.map(item => transformToCamelCase(item));
    }
    else if (obj !== null && typeof obj === 'object') {
        if (obj instanceof Date) {
            return obj;
        }
        const transformedObj = {};
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const transformedKey = key.replace(/[_-]([a-z])/g, (_, match) => match.toUpperCase());
                transformedObj[transformedKey] = transformToCamelCase(obj[key]);
            }
        }
        return transformedObj;
    }
    else {
        return obj;
    }
};
