export class CustomDate {
    static getDay(date) {
        return new Date(date).getDate();
    }
    static getMonth(date, options = 'short') {
        return new Date(date).toLocaleDateString('en-FI', { month: options });
    }
    static getHours(date) {
        return new Date(date).getHours();
    }
    static getMinutes(date) {
        return new Date(date).getMinutes().toString().padStart(2, '0');
    }
    static getFormat(date) {
        return CustomDate.getHours(date) >= 12 ? 'pm' : 'am';
    }
    static getYear(date, options = '2-digit') {
        return new Date(date).toLocaleDateString('en-FI', { year: options });
    }
    static formatCustomDate(date) {
        const formattedDate = `${CustomDate.getMonth(date)} ${CustomDate.getDay(date)}, ${CustomDate.getYear(date, 'numeric')} @${CustomDate.getHours(date)}:${CustomDate.getMinutes(date)}`;
        return formattedDate;
    }
    static formatDate(date) {
        return (date &&
            new Date(date).toLocaleDateString('en-FI', {
                year: 'numeric',
                month: 'long'
            }));
    }
    static formatDateWithDay(date) {
        return (date &&
            new Date(date).toLocaleDateString('en-FI', {
                year: '2-digit',
                month: 'short',
                day: '2-digit'
            }));
    }
    static getToday() {
        return new Date().toISOString().slice(0, 16);
    }
    static getDateTimeLocal(date) {
        const year = new Date(date).toLocaleString('en-FI', {
            year: 'numeric'
        });
        const month = new Date(date).toLocaleString('en-FI', {
            month: '2-digit'
        });
        const day = new Date(date).toLocaleString('en-FI', { day: '2-digit' });
        const hour = new Date(date).toLocaleString('en-FI', {
            hour: '2-digit'
        });
        const minute = new Date(date)
            .toLocaleString('en-FI', {
            minute: '2-digit'
        })
            .toString()
            .padStart(2, '0');
        return `${year}-${month}-${day}T${hour}:${minute}`;
    }
}
