import { FaCalendarDays } from 'react-icons/fa6';
import { CustomDate } from '@church/utilities';
import { IEvent } from '../types/event';
import { ENV } from '../utils/contants';
import { Link } from 'react-router-dom';

export default function Event({
    slug,
    startDate,
    images,
    title,
    location,
    endDate,
    fee
}: IEvent) {
    return (
        <Link
            className='flex flex-col md:flex md:flex-row cursor-pointer gap-6 my-2'
            to={`/events/${slug}`}
        >
            <div>
                <p className='font-bold text-3xl'>
                    {CustomDate.getDay(startDate)}
                </p>
                <p className='text-gray-500'>{CustomDate.getMonth(endDate)}</p>
            </div>
            <div>
                {images && images.length > 0 && (
                    <div className='md:w-96 md:h-52'>
                        <img
                            className='rounded-lg object-cover w-full h-full'
                            src={`${ENV.images}/${images[0].url}`}
                            alt={title}
                        />
                    </div>
                )}
                <div className='my-3'>
                    <h1 className='font-bold text-xl mb-2'>{title}</h1>
                    <div>
                        <h2 className='text-gray-500 font-bold mb-2'>
                            {location}
                        </h2>
                        <div className='flex items-center'>
                            <FaCalendarDays className='h-4 w-4 fill-slate-400 mr-1' />
                            <p>
                                <small>
                                    {CustomDate.formatCustomDate(startDate)} -{' '}
                                    {CustomDate.formatCustomDate(endDate)}
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <h1 className='text-2xl text-gray-500'>{`${
                +fee === 0 ? 'Free' : fee + '€'
            }`}</h1>
        </Link>
    );
}
